<template>
  <v-card>
    <v-card-title primary-title>
       <span v-if="appointments.length > 0">
        WARNING You are reducing the block capacity below the scheduled count.
      </span>
      <span v-if="appointments.length < 1">
        All appointments released. Close this window and click Save to complete.
      </span>
    </v-card-title>
    <v-card-subtitle>
      <span v-if="appointments.length > 0">
        Please release appointments below the capacity and try again. Please
        notify appointments before releasing from schedule.
        {{ appointments.length || 0 }} appointments in this block
      </span>
    </v-card-subtitle>
     <v-card-text>
      <v-row v-if="loadingBlockAppointments">
        <v-col cols="12" sm="12" class="text-center">
          <v-progress-circular
            :size="100"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      dense
      :headers="headers"
      :items="appointments"
      class="elevation-1"
    >
      <template v-slot:[`item.datetime_start`]="{ item }">
        <span>{{ getPrettyTime(item.datetime_start) }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn color="error" x-small @click="releaseAppointment(item)" :disabled="releasingAppt"
          >Release</v-btn
        >
      </template>
    </v-data-table>
    <v-card-actions>
      <v-btn color="primary" @click="closeme()"> Close </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  props: {
    block: {
      type: Object,
      default: () => {},
      required: true
    },
  },
  data() {
    return {
      appointments: [],
      headers: [
        { text: "Start Date", value: "datetime_start" },
        { text: "Name", value: "employeeFullName" },
        { text: "Phone", value: "phone" },
        { text: "Actions", value: "actions" },
      ],
    };
  },
  methods: {
    ...mapActions("users", ["deleteAppointment"]),
    ...mapActions("vaccineLocations", ["deleteSingle"]),
    ...mapActions("appointmentBlock", ["getMultiBlockAppts"]),
    releaseAppointment: function (item) {
      this.deleteAppointment({ appointmentId: item.id, personId: item.user_id}).then((response) => {
        this.getMultiBlockAppts({ ...this.block, blocks: this.block.id }).then((response) => {
          this.appointments = response;
        });
      });
    },
    closeme() {
      this.$emit("closeCapacityList");
    },
    convertToLocal: function (date) {
      return moment.utc(date).local();
    },
    getPrettyTime: function (date) {
      return this.convertToLocal(date).format("MMM Do h:mm a");
    },
  },
  computed: {
    ...mapGetters("appointmentBlock", ["loadingBlockAppointments"]),
    ...mapGetters("users", ["releasingAppt"])
  },
  mounted() {
    //if (Object.keys(this.block).length > 1) {
      this.getMultiBlockAppts({ ...this.block, blocks: this.block.id }).then((response) => {
        this.appointments = response;
      });
   // }
  },
};
</script>
